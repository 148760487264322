import Verslo1 from '../assets/verslo1.png'
import Verslo2 from '../assets/MUG2.png'


const Grafiniai = () => {

    return (
        <div className="verslo" id='VersloDovanos'>
            <div className='versloName'>
                <a>VERSLO DOVANOS</a>
            </div>
            <div className='verslo1'>
                <img src={Verslo1} alt='Verslo dovana1'/>
            </div>
            <div className='verslo2'>
                <img src={Verslo2} alt='Verslo dovana2'/>
            </div>
        </div>

    )
}

export default Grafiniai
