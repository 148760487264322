import { useState } from "react";

const NavList = (props) => {

    const [open, setOpen] = useState(false);

    return (
        <div>
            <div className="mobileNavigationList">
                <a onClick={() => props.isOpen && props.closeMenu()} key='Apie' className="navItem" href="#Apie">Apie</a>
                <a onClick={() => props.isOpen && props.closeMenu()} key="Logo" className="navItem1" href="#Logo">Logotipas</a>
                <a onClick={() => props.isOpen && props.closeMenu()} key="Spalvos" className="navItem2" href="#Spalvos">Spalvos</a>
                <a onClick={() => props.isOpen && props.closeMenu()} key="Tipografija" className="navItem3" href="#Tipografija">Tipografija</a>
                <a onClick={() => props.isOpen && props.closeMenu()} key="VersloDovanos" className="navItem4" href="#VersloDovanos">Verslo dovanos</a>
            </div>
        </div>
    )
}

export default NavList
