import './App.css';
import './AppMob.css';
import React, { useContext, useEffect, useState } from 'react';
import Navigation from './Navbar';
import TaskContextProvider, { TaskContext } from "./AppStates"
import { Link, NavLink } from "react-router-dom";
import {BrowserRouter, Routes,  Route} from "react-router-dom";
import Home from "./routes/Home";
import Apie from "./components/Apie";
import Logo from "./components/Logo";
import Spalvos from "./components/Spalvos";
import Tipografija from "./components/Tipografija";
import VersloDovanos from "./components/VersloDovanos";
import LogoMain from "./components/LogoMain";
import LogoVar from "./components/LogoVar";
import NavList from './components/NavList';
import Footer from './components/Footer';



function App() {
  return (
    <TaskContextProvider>
      <BrowserRouter>
        <Navigation />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Apie" element={<Apie />} />
        <Route path="/Logo" element={<Logo />} />
        <Route path="/Spalvos" element={<Spalvos />} />
        <Route path="/Tipografija" element={<Tipografija/>} />
        <Route path="/VersloDovanos" element={<VersloDovanos/>} />
        <Route path="/LogoMain" element={<LogoMain/>} />
        <Route path="/LogoVar" element={<LogoVar/>} />
        <Route path="/NavList" element={<NavList/>} />
        <Route path="/Footer" element={<Footer/>} />

    </Routes>
      </BrowserRouter>
    </TaskContextProvider>
  );
}

export default App;