import lottie from "lottie-web";
import Sucrelogo from '../assets/animacija.json'
import React from "react";



const LogoMain = () => {

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: Sucrelogo,
      loop: false,
    });
  }, []);

  return (
    <div className='LogoMainCont'>
      <div className="LogoMain" id="react-logo"></div>
    </div>
  )
}

export default LogoMain
