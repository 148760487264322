import Color from '../assets/color.png'


const Spalvos = () => {

    return (
        <div className="spalvos" id='Spalvos'>
            <div className='spalvos2'>

                <div className='color'>
                    <img src={Color} alt='spalvos'/>
                </div>
                <div className='spalvosCont'>
                    <div className='spalvosTxt'>
                        <p>
                        Vienas svarbiausių išskirtų elementų yra ryškios bei kontrastingos spalvos. Šiam interneto stiliaus gidui buvo parinkta penkių spalvų paletė įkvėpta <a className='BobDylan' href="https://www.miltonglaser.com/the-work/444/columbia-records-poster-for-bob-dylans-greatest-hits-1967/">"Bob Dylan Greatest Hits", </a>
                        <a className='Aretha' href='https://www.miltonglaser.com/the-work/c:posters/561/eye-magazine-aretha/'>"Aretha" </a>
                         plakatų. Spalvos kontrastuoja su šviesiu #F4E8D3 spalvos fonu. 
                         </p>
                    </div>
                    <div className='spalvosName'>
                    <h2 className="logoName2">
                        SPALVOS
                    </h2>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Spalvos
