import logo from '../assets/logo.png'
import logo1 from '../assets/logo1.png'
import React from "react";


const LogoVar = () => {

    return (
        <div className='logoVarCont'>

        <div className="LogoVar">
            <div className='logovar1 move-me-2'>
                <img src={logo1} alt='logo' />
            </div>
            <div className='logovar2 move-me-1'>
                <img src={logo} alt='logo' />
            </div>
        </div>
        </div>

    )
}

export default LogoVar
