const Footer = () => {

    return (
        <div className="footer" id='Footer'>
            <div className='footer1'>
    
            </div >
            <div className='footer2'>
            <p>Laima Kvedaravičiūtė</p>
            </div>
            <div className='footer3'>
            <p>Baigiamasis bakalauro darbas</p>
            </div>
            <div className='footer4'>
            <p>@SUCRE, 2022</p>

            </div>
            <div className='footer5'>

            </div>
        </div>

    )
}

export default Footer
