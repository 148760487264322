
const Tipografija = () => {

    return (
        <div className="tipgrafija" id='Tipografija'>
            <div className='tipografijaCont'>
                <div className='tipografijaName'>
                    <a className='tipografijaName2'>TIPOGRAFIJA</a>
                </div>
                <div className='tipografijaTxt1'>
                    <p className='tipografijaTxtName'>
                        PROHIBITION
                    </p>
                    <p className='tipografijaTxtCont'>
                        Prohibition šriftas, taip pat naudojamas ir logotipe, yra taikomas skilties pavadinimui. Jis yra platus, nukirstais kampais, primenantis standartines geometrines formas.
                    </p>
                </div>
                <div className='tipografijaTxt2'>
                    <p className='tipografijaTxtName'>
                        NUNITO SANS
                    </p>
                    <p className='tipografijaTxtCont'>
NUNITO SANS šriftas taikomas skilčių aprašymam. Jis yra plonas, lengvai įskaitomas bei derantis su pavadinimams naudojamu šriftu Prohibition.
                    </p>
                </div>
            </div>
        </div>

    )
}

export default Tipografija
