import logo_wireframe from '../assets/logo_wireframe.png'
import logo_mid from '../assets/logo_mid.png'
import logo from '../assets/logo.png'



const Logo = () => {

    return (
        <div className="fill-window" id='Logo'>
            <div className="logoTop">
                <div className="logoTopLeft">
                    <h2 className="logoName">
                        LOGOTIPAS
                    </h2>
                </div>
                <div className="logoTopRight">
                    <p className="logoTxt">
                        <ul className="logoTxt2">
                            <li>
                        "Sucre" logotipas susidaro iš pavadinimo ir emblemos. Emblema reprezentuoja pirmąja pavadinimo raidę "S", o spalvos parinktos iš gerai žinomo M. Glaser‘io plakato <a className='BobDylan' href="https://www.miltonglaser.com/the-work/444/columbia-records-poster-for-bob-dylans-greatest-hits-1967/">„Bob Dylan Greatest Hits“.</a> 
                     </li>  <li> 
                        Logotipo emblema yra įrėminama į pilkos spalvos kvadratą, viršuje parašomas pavadinimas "Prohibition" šriftu.
                        </li>
                        <li>
                        Galimas ir baltas ir juodas logotipo pavadinimas.
                        </li>
                        <li>
                        Galimos logotipo variacijos: pilnas logotipas arba tik emblema.
                        </li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="logoBot">
                <div >
                <img src={logo_wireframe} alt='Logo wireframe' />
                </div>
                <div>

                <img src={logo_mid} alt='Logo be teksto' />
                </div>
                <div className='logoBot1'>

                <img src={logo} alt='Logo'/>
                </div>
            </div>
        </div>

    )
}

export default Logo
