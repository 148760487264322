import { useContext, useEffect, useRef, useState } from "react";
// import Logo_png from "./logo.png"
import Apie from '../components/Apie';
import Logo from '../components/Logo';
import LogoMain from "../components/LogoMain";
import Spalvos from '../components/Spalvos';
import VersloDovanos from '../components/VersloDovanos';
import Banga from '../components/banga.png'
import LogoVar from "../components/LogoVar";
import Footer from "../components/Footer";
import Tipografija from "../components/Tipografija";





const Home = () => {

    return (
        <div className="home">
            <div className="bangaMain">
                <img src={Banga} alt='5 linijos'/>
            </div>
            <div className="homeCont">
                <LogoMain />
                <Apie />
                <Logo />
                <LogoVar />
                <Spalvos />
                <Tipografija />
                <VersloDovanos />
                <Footer />
            </div>
        </div>
    )
}

export default Home
