

const Apie = () => {

    return (
        <div className="apie" id='Apie'>
            <div className="apieLeft">
                <div className="apieTxtCont">
                    <div className="apieLeftTxtDiv">
                        <h2 className="apieName">
                            APIE
                        </h2>
                        <p className="apieTxt">
                            Ši svetainė yra kūrybinis bakalauro darbo projektas, kuriame yra reprezentuojantuojama grafikos dizainerio Milton'o Glaser'io dizaino stilistika.
                        </p>
                    </div>
                </div>
            </div>
            <div className="apieRight">
                <div className="apieTxtCont">
                    <p className="apieTxt">
                        Atlikus Milton'o Glaser'io kūrybinių darbų analizę išskirti pagrindiniai dizaino gramatikos elementai, kuriais remiantis buvo daromas kūrybinis projektas:
                        <ul className="apieLi">
                            <li>ryškios, kontrastingos spalvos;</li>
                            <li>įvarios geometrinės formos bei jų kompozicijos;</li>
                            <li>įdomi tipografija.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>

    )
}

export default Apie
