import { useState } from "react";
import ham from "./assets/ham.png"
import back from "./assets/back.png"
import NavList from "./components/NavList";

const Navigation = () => {

    const [open, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);
    const hamburger = <div className="ham"
        onClick={() => {
            setOpen(!open);
        }}>
        <img src={ham} alt="Logo" />
    </div>

    const closeHamburger = <div className="ham"
        onClick={() => {
            setOpen(!open);
        }}>
        <img src={back} alt="Atgal" />
    </div>

    return (
        <div className="navBar">

            <nav key='navigationConainer' className="mobileNav">
                {open ? closeHamburger : hamburger}

                {open && <NavList isOpen={true} closeMenu={closeMenu} />}
            </nav>
        </div>

    )
}

export default Navigation
